import React from "react";
// scss
import "../styles/Footer.scss";
import "../styles/products.scss"

const Footer = () => {
  return (
    <>
      <footer className="footer43 ">
        <div className="container">
          <div className="text-center">
            <p className="headingstextstyle pt-3 text-white" style={{ fontSize: "1.8rem" }}>
              Stay Connected
            </p>
            <form className="mb-3 normaltextstyle">
              <p className="p8t2">
                <input className="input email__input3 me-3" name="text" placeholder="&nbsp;Email Address" type="Email Address"/>
                <button className="p8btn">S u b s c r i b e</button>
              </p>
            </form>
            <br />
          </div>
          <div className="row normaltextstyle">
            <div className="col text-white">Pragnya Mobility Pvt. Ltd.</div>
            <div className="col">
              <ul style={{ listStyle: "none", display: "flex", justifyContent: "center", color: "whitesmoke" }}>
                <li style={{ marginRight: "30px", fontSize: "22px" }}><i className="fa-brands fa-instagram"></i></li>
                <li style={{ marginRight: "30px", fontSize: "22px" }}><i className="fa-brands fa-x-twitter"></i></li>
                <li style={{ marginRight: "30px", fontSize: "22px" }}><i className="fa-brands fa-youtube"></i></li>
                <li style={{ fontSize: "22px" }}><i className="fa-brands fa-linkedin"></i></li>
              </ul>
            </div>
            <div className="col text-white text-end">Pragnya © 2024. All rights reserved</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;