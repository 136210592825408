import React from "react";
import Landingpage from "./components/Landingpage.jsx";
import Products from "./components/Products.jsx";
import Contactus from "./components/Contactus.jsx";
import Aboutus from "./components/Aboutus.jsx";
import { Routes, Route } from "react-router-dom";
import "./App.scss"

const App = () => {
  return (
    <React.Fragment>
      <div>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/aboutus" element={<Aboutus />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default App;
