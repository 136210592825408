import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../images/Frame1.png";
import "../styles/Navbar.scss";
import product1 from "../images/product1.png";
import product2 from "../images/product2.png";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useRef } from "react";

function CollapsibleExample() {
  const [show, setShow] = useState(false);
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const popupClose = () => setShow(false);
  const handleOffcanvasClose = () => setOffcanvasShow(false);
  const handleOffcanvasShow = () => setOffcanvasShow(true);
  // const [registershow, setregisterShow] = useState(false);
  // const RegisterhandleShow = () => setregisterShow(true);
  // const RegisterhandleClose = () => setregisterShow(false);
  // for mega menu bro
  const [isServiceActive, setIsServiceActive] = useState(false);
  // const service_activate = () => {
  //   setIsServiceActive(true);
  // };
  // const service_inactivate = (elementId) => {
  //   setIsServiceActive(false);
  // };
  // const location = useLocation();
  // const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  // const toggleOffcanvas = () => {
  //   setIsOffcanvasOpen(!isOffcanvasOpen);
  // };
  const toggleServicesDropdown = () => {
    setServicesOpen(!servicesOpen);
  };
  const closeDropdowns = () => {
    // setIsOffcanvasOpen(false);
    setServicesOpen(false);
  };
  const servicesNavItem = useRef(null);
  const servicesDropdownMenu = useRef(null);
  const megaMenuRef = useRef(null);
  const handleBodyClick = (event) => {
    if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
      closeDropdowns();
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [megaMenuRef]);
  useEffect(() => {
    const servicePageElements = document.getElementById("myElement345");
    if (servicePageElements) {
      if (isServiceActive) {
        servicePageElements.classList.add("active");
      } else {
        servicePageElements.classList.remove("active");
      }
    }
  }, [isServiceActive]);

  // for login
  const [loginShow, setLoginShow] = useState(false);

  const handleLoginClose = () => setLoginShow(false);
  const handleLoginShow = () => setLoginShow(true);

  // for register
  const [RegisterShow, setRegisterShow] = useState(false);

  const handleRegisterClose = () => setRegisterShow(false);
  const handleRegisterShow = () => setRegisterShow(true);

  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar collapseOnSelect expand="lg" className="bg-dark" variant="dark" fixed="top" >
        <Container>
          <NavLink to="/">
            <img className="logoimg" src={Logo} alt="" />
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <ul className="navbar-nav nav-ul">
                {/* <li
                  className={`nav-item nav_item325 megamenu ${
                    servicesOpen ? "show" : ""
                  }`}
                  onClick={toggleServicesDropdown}
                  ref={megaMenuRef}
                >
                  <NavLink
                    id="myElement345"
                    className="nav-link disable-click nav__links"
                    ref={servicesNavItem}
                    onClick={closeDropdowns}
                  >
                    Elysian Odyssey
                  </NavLink>
                  <div
                    id="servicesDropdownMenu"
                    ref={servicesDropdownMenu}
                    className={`dropdown-menu bg-dark rounded-0 ${
                      servicesOpen ? "show" : ""
                    }`}
                    style={{ width: "100%", marginTop: "-2px" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col text-center pt-2 pb-3">
                          <NavLink to="/products">
                            <img
                              src={product1}
                              className="forproductsize hover-opacity"
                              alt=""
                              onClick={popupClose}
                            />
                          </NavLink>
                        </div>
                        <div className="col text-center pt-2 pb-3">
                          <NavLink to="/products">
                            <img
                              src={product2}
                              className="forproductsize hover-opacity"
                              alt=""
                              onClick={popupClose}
                            />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> */}
                <li className="nav-item">
                  <NavLink to="/products" className="nav-link nav__links" >
                    Elysian Odyssey
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contactus" className="nav-link navcontact nav__links" >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/aboutus" className="nav-link nav__links">
                    About Us
                  </NavLink>
                </li>
              </ul>
            </Nav>
            {/* <Nav className="ml-auto">
              <NavLink className="nav-link">
                <FontAwesomeIcon icon={faUser} />
              </NavLink>
              <NavLink className="nav-link">
                <FontAwesomeIcon icon={faBars} onClick={handleOffcanvasShow} />
              </NavLink>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Offcanvas className="bg-dark" show={offcanvasShow} onHide={handleOffcanvasClose} placement="end">
        <Offcanvas.Header className="d-lg-none d-md-none d-sm-block" closeButton ></Offcanvas.Header>
        <Offcanvas.Body>
          <button className="loginbtn mt-5" onClick={handleLoginShow}>
            Login
          </button>
          <button className="Registerbtn" onClick={handleRegisterShow}>Register</button>
          <div className="text-white mt-5">
            <p className="gbdgf">Our Technology</p>
            <p className="gbdgf">Our Team</p>
            <p className="gbdgf">Events</p>
            <p className="gbdgf">Contact Us</p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* Login form*/}
      <Modal show={loginShow} onHide={handleLoginClose} centered className="fsfsd mx-auto" >
        <Modal.Header closeButton style={{ borderBottom: "none" }} ></Modal.Header>
        <Modal.Body>
          <form>
            <div className="d-flex justify-content-center gootop">
              <span className="d-flex justify-content-center border rounded me-5 googlespan__">
                <div>
                  <FcGoogle className="googicon" />
                </div>
                <div className="textkawidth" style={{ fontSize: "14px" }}>Sign up with Google</div>
              </span>
              <span className="d-flex justify-content-center border rounded googlespan__ googlespan__1">
                <div>
                  <FaApple className="googicon" />
                </div>
                <div className="textkawidth" style={{ fontSize: "14px" }}> Sign up with Apple</div>
              </span>
            </div>
            <p className="text-center">OR</p>
            <div className="form-group emailcss98">
              <label for="exampleInputEmail1">Email</label>
              <input type="email" className="form-control rounded-0 inputcss" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required/>
            </div>
            <div className="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input type="password" className="form-control rounded-0 inputcss" id="exampleInputPassword1" placeholder="Enter Password" required/>
            </div>
            <div className="form-group">
              <label className="text-primary">Forget Password ?</label>
            </div>
            <button type="submit" className="btn btn-primary rounded-1 w-100">
              Sign in
            </button>
          </form>
        </Modal.Body>
      </Modal>
      {/* Register form*/}
      <Modal show={RegisterShow} onHide={handleRegisterClose} centered className="fsfsd mx-auto" >
        <Modal.Header closeButton style={{ borderBottom: "none" }} ></Modal.Header>
        <Modal.Body>
          <form>
            <div className="d-flex justify-content-center gootop">
              <span className="d-flex justify-content-center border rounded me-5 googlespan__">
                <div>
                  <FcGoogle className="googicon" />
                </div>
                <div className="textkawidth" style={{ fontSize: "14px" }}>Sign up with Google</div>
              </span>
              <span className="d-flex justify-content-center border rounded googlespan__ googlespan__1">
                <div>
                  <FaApple className="googicon" />
                </div>
                <div className="textkawidth" style={{ fontSize: "14px" }}> Sign up with Apple</div>
              </span>
            </div>
            <p className="text-center">OR</p>
            <div className="form-group fullnamecss5">
              <label>Full Name</label>
              <input type="text" className="form-control rounded-0 inputcss" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Full Name" required />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">Email</label>
              <input type="email" className="form-control rounded-0 inputcss" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required />
            </div>
            <div className="form-group mt-1">
              <label for="exampleInputPassword1">Password</label>
              <input type="password" className="form-control rounded-0 inputcss" id="exampleInputPassword1" placeholder="Enter Password" required />
            </div>
            <button type="submit" className="btn btn-primary rounded-1 w-100">
              Register
            </button>
          </form>
        </Modal.Body>
      </Modal>
      {/* Products Modal */}
      <Modal show={show} onHide={handleClose} container={document.body} dialogClassName="full-screen-modal" >
        <Modal.Body className="bg-dark">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <NavLink to="/products">
                  <img src={product1} className="forproductsize hover-opacity" alt="" onClick={popupClose} />
                </NavLink>
              </div>
              <div className="col text-center">
                <NavLink to="/products">
                  <img src={product2} className="forproductsize hover-opacity" alt="" onClick={popupClose} />
                </NavLink>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CollapsibleExample;
