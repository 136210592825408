import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/products.scss";
import productsimg1 from "../images/productsimg1.webp";
import verticalship from "../images/verticalship.webp";
import productimg4 from "../images/productimg4.webp";
import SunriseDesktop from "../images/Arc-One-Sunrise-Desktop-1920.webp";
import MistyWake from "../images/Arc-One-Misty-Wake-Turn-1920.webp";
import WeldingFactory from "../images/Arc-Boats-Welding-Factory-1920.webp";
 
import Footer from "./Footer";
import Navbar from "./Navbar";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hiding_scroll products_mainfw">
      <div style={{scrollSnapAlign:"start"}}>
      <Navbar />
      </div>
      <div style={{scrollSnapAlign:"start"}}>
      <Products1 />
      </div>
      <div style={{scrollSnapAlign:"start"}}>
      <Products2 />
      </div>
      <div style={{scrollSnapAlign:"start"}}>
      <Products3 />
      </div>
      <div style={{scrollSnapAlign:"start"}}>
      <Products4 />
      </div>
      <div style={{scrollSnapAlign:"start"}}>
      <Products5 />
      </div>
      <div style={{scrollSnapAlign:"start"}}>
      <Footer />
      </div>
    </div>

  );
};

const Products1 = () => {
  return (
    <>
      <div
        className="products11 headingstextstyle"
        style={{ backgroundImage: `url(${productsimg1})` }}
      >
        <p className="products12">
          <p className="products13">ELYSIAN ODYSSEY</p>
          <p className="products14">First of its kind.</p>
        </p>
        <div
          className="container products15 text-white"
          style={{ fontWeight: "500" }}
        >
          {/* <div className="row">
                        <div className="col text-white">
                            Salling Range <br /> 500 mile+
                        </div>
                        <div className="col text-white">
                            0 to 80% <br /> 30 Mins
                        </div>
                        <div className="col text-white">
                            Battery Life <br />
                            250,000 km
                        </div>
                    </div> */}
          <p className="products16" >
            <span style={{ fontSize: "18px" }}>Power</span>
            <br />
            <span style={{ fontSize: "28px" }}>500hp</span>
          </p>
          <p className="products17" >
            <span style={{ fontSize: "18px" }}>Speed</span>
            <br />
            <span style={{ fontSize: "28px" }}>40mph</span>
          </p>
          <p className="products18" >
            <span style={{ fontSize: "18px" }}>Emissions</span>
            <br />
            <span style={{ fontSize: "28px" }}>Zero</span>
          </p>
        </div>
      </div>
    </>
  );
};

const Products2 = () => {
  return (
    <>
      <div className="row" style={{ height: "100vh" }}>
        <div
          className="col mt-5 d-flex align-items-center"
        >
          <div className="container products41">
            <span className="products42 headingstextstyle">Performance</span>
            <br />
            <span className="normaltextstyle" style={{fontSize:"17px"}}>
              Pragnya One pairs a massive battery with a powerful motor, so you
              can enjoy the water instead of searching for the gas dock.
            </span>
            <br />
            <p className="explore_btn headingstextstyle">EXPLORE DETAILS +</p>
          </div>
        </div>
        <div className="col mt-5 d-flex align-items-center">
          <img
            className="product4img"
            src={productimg4}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

const Products3 = () => {
  return (
    <>
      <div className="row" style={{ height: "100vh" }}>
        <div className="col mt-5 d-flex align-items-center">
          <img
            className="product5img"
            src={productimg4}
            alt=""
          />
        </div>
        <div
          className="col mt-5 d-flex align-items-center">
          <div className="container products41">
            <span className="products42 headingstextstyle">Style</span>
            <br />
            <span style={{fontSize:"17px"}}>
              An elegant, minimalist design stands out from everything else at
              the marina.
            </span>
            <br />
            <p className="explore_btn headingstextstyle">EXPLORE DETAILS +</p>
          </div>
        </div>
      </div>
    </>
  );
};

const Products4 = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const texts = [
    <div
      key={0}
      className="text-start maindivpp1"
    >
      <div className="forppwidth-landing56">
        <p className="pp6_t1 headingstextstyle">LIGHTWEIGHT HULL</p>
        {/* <p className="pp6_t2 normaltextstyle">Marine-grade aluminum and modern manufacturing techniques unlock superior range and durability.</p> */}
      </div>
      <hr className="hrline54"/>
    </div>,
    <div
      key={0}
      className="text-start maindivpp2"
    >
      <div className="forppwidth-landing56">
        <p className="pp6_t1 headingstextstyle">PURPOSE-BUILT</p>
        {/* <p className="pp6_t2 normaltextstyle">Battery packs designed exclusively for the Arc One provide tight control over your center of gravity — and free up extra space too.</p> */}
      </div>
      <hr className="hrline54" />
    </div>,
    <div
      key={0}
      className="text-start maindivpp3"
    >
      <div className="forppwidth-landing56">
        <p className="pp6_t1 headingstextstyle">
          AERO ENGINEERING
        </p>
        {/* <p className="pp6_t2 normaltextstyle">Cutting-edge technology and design from a team of former rocket engineers.</p> */}
      </div>
      <hr className="hrline54" />
    </div>,
  ];
  const imagesSets = [
    [
      { src: SunriseDesktop, className: "first-image871" },
      {
        text: "Marine-grade aluminum and modern manufacturing techniques unlock superior range and durability.",
        className: "right-text4 normaltextstyle",
      },
    ],
    [
      { src: MistyWake, className: "first-image871" },
      {
        text: "Battery packs designed exclusively for the Arc One provide tight control over your center of gravity — and free up extra space too.",
        className: "right-text5 normaltextstyle",
      },
    ],
    [
      { src: WeldingFactory, className: "first-image871" },
      {
        text: "Cutting-edge technology and design from a team of former rocket engineers.",
        className: "right-text6 normaltextstyle",
      },
    ],
  ];

  const handleTextClick = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [texts.length]);
  return (
    <>
      <div style={{ marginTop: "60px", marginBottom:"40px" }}>
        <div style={{ display: "flex" }}>
          {texts.map((text, index) => (
            <div
              key={index}
              style={{
                flex: 1,
                opacity: index === activeIndex ? 1 : 0.5,
                cursor: "pointer",
                transition: "opacity 0.5s ease",
              }}
              onClick={() => handleTextClick(index)}
            >
              {text}
            </div>
          ))}
        </div>
        <div>
          {imagesSets[activeIndex].map((item, idx) =>
            item.src ? (
              <img key={idx} src={item.src} alt="" className={item.className} />
            ) : (
              <div key={idx} className={item.className}>
                {item.text}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

const Products5 = () => {
  return (
    <>
      <div
        className="products81"
        style={{ marginTop: "20px", marginBottom:"65px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div
                className="key-details-table"
                data-appear-stagger=""
                style={{fontSize:"13px"}}
              >
                <div className="tr headingstextstyle" >
                  <div className="td">PRAGNYA ONE</div>
                </div>
                <div className="tr normaltextstyle" >
                  <div className="td">Length</div>
                  <div className="td">24 ft</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Beam</div>
                  <div className="td">102 in</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Draft</div>
                  <div className="td">33 in</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Hull Material</div>
                  <div className="td">Aluminum</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Power</div>
                  <div className="td">500 hp</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Top Speed</div>
                  <div className="td">40 mph</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Battery</div>
                  <div className="td">220 kWh</div>
                </div>

                <div className="tr normaltextstyle" >
                  <div className="td">Avg Usage</div>
                  <div className="td">3-5 hrs</div>
                </div>
              </div>
            </div>
            <div className="col">
              <img
                className="hidden-xs"
                src={verticalship}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;