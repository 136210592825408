import React, { useEffect, useState, useRef } from "react";
// scss
import "../styles/Landingpage.scss";
// images
import headerImage from "../images/Frame2.png";
import imagep31 from "../images/Frame3.png";
import logowithbg from "../images/logowithbg.png";
import Frame4 from "../images/Frame4.png";
import VerticalLine from "../images/VerticalLine.png";
import SunriseDesktop from "../images/Arc-One-Sunrise-Desktop-1920.webp";
import MistyWake from "../images/Arc-One-Misty-Wake-Turn-1920.webp";
import WeldingFactory from "../images/Arc-Boats-Welding-Factory-1920.webp";
// jsx
import Footer from "./Footer";
import Navbar from "./Navbar";
// icons
import { PiUser } from "react-icons/pi";
import { TfiEmail } from "react-icons/tfi";
import { PiPhone } from "react-icons/pi";
// offcanvas
import Offcanvas from "react-bootstrap/Offcanvas";

const Landingpage = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 1,
      });
      scrollContainer.scrollTo({
        top: 1,
      });
    }
  }, []);

  return (
    <div className="hiding_scroll landing_manipage" ref={scrollRef}>
      <div style={{ scrollSnapAlign: "start" }}>
        <Navbar />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Landingpage1 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Landingpage3 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Landingpage5 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Landingpage7 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Footer />
      </div>
    </div>
  );
};

const Landingpage1 = () => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const handleOffcanvasClose = () => setOffcanvasShow(false);
  const handleOffcanvasShow = () => setOffcanvasShow(true);

  return (
    <>
      <div>
        <div
          className="header"
          style={{ backgroundImage: `url(${headerImage})` }}
        >
          <div className="text-overlay">
            <p
              className="p1t1 headingstextstyle"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              Revolutionizing Maritime Travel with Sustainable Elegance.
            </p>
            <p className="p1t2 normaltextstyle">
              Experience the Future of Luxury at Sea with Our State-of-the-Art
              Electric Boats and Yachts.
            </p>
            <button
              className="p1btn normaltextstyle"
              onClick={handleOffcanvasShow}
            >
              Book Now
            </button>
            <Offcanvas
              className="bg-dark"
              show={offcanvasShow}
              onHide={handleOffcanvasClose}
              placement="end"
              style={{ width: "450px" }}
            >
              <Offcanvas.Header
                className="d-lg-none d-md-none d-sm-block"
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-white d-flex justify-content-center">
                  <form
                    action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DF9000000caJH"
                    method="POST"
                    style={{ width: "260px" }}
                  >
                    <input type="hidden" name="oid" value="00DF9000000caJH" />
                    <input
                      type="hidden"
                      name="retURL"
                      value="https://pragnyamobility.com"
                    />
                    <div className="form-group">
                      <label for="first_name">First Name*</label>
                      <input
                        className="form-control bg-dark rounded-1 text-white custom-input"
                        aria-describedby="emailHelp"
                        placeholder="Enter First Name"
                        autocomplete="off"
                        id="first_name"
                        maxlength="40"
                        name="first_name"
                        size="20"
                        type="text"
                        required
                      />
                      <PiUser className="book_icon" />
                    </div>
                    <div className="form-group">
                      <label for="last_name">Last Name*</label>
                      <input
                      id="last_name" maxlength="80" name="last_name" size="20" type="text"
                        className="form-control bg-dark rounded-1 text-white custom-input"
                        aria-describedby="emailHelp"
                        placeholder="Enter First Name"
                        autocomplete="off"
                        required
                      />
                      <PiUser className="book_icon" />
                    </div>
                    <div className="form-group">
                      <label for="email">Email*</label>
                      <input
                      id="email" maxlength="80" name="email" size="20" type="text"
                        className="form-control bg-dark rounded-1 text-white custom-input"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email"
                        autocomplete="off"
                        required
                      />
                      <TfiEmail className="book_icon" />
                    </div>
                    <div className="form-group">
                      <label for="mobile">Mobile*</label>
                      <input
                      id="mobile" maxlength="40" name="mobile" size="20" type="text"
                        className="form-control bg-dark rounded-1 text-white custom-input"
                        aria-describedby="emailHelp"
                        placeholder="Enter Mobile"
                        autocomplete="off"
                        required
                      />
                      <PiPhone className="book_icon" />
                    </div>
                    <div className="form-group">
                      <label for="description">Message*</label>
                      <textarea
                      name="description"
                        type="text"
                        className="form-control bg-dark rounded-1 text-white custom-input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Message"
                        autocomplete="off"
                        rows="2"
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        name="submit"
                        className="mt-2 btn text-white rounded-1 custom-button"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            {/* Register form*/}
            {/* <Modal
            show={BookShow}
            onHide={handleBookClose}
            centered
            className="fsfsd mx-auto"
          >
            <Modal.Header
              closeButton
              style={{ borderBottom: "none" }}
            ></Modal.Header>
            <Modal.Body>
              <form>
                <div className="form-group firstcss">
                  <label className="labels3243">First Name</label>
                  <input
                    type="text"
                    className="form-control rounded-0 inputcss"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter First Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="labels3243">Last Name</label>
                  <input
                    type="text"
                    className="form-control rounded-0 inputcss"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Last Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="labels3243">Phone Number</label>
                  <input
                    type="text"
                    className="form-control rounded-0 inputcss"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="XXXXXXXXXX"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="labels3243" for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    className="form-control rounded-0 inputcss"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="example@gmail.com"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary rounded-1 w-100"
                >
                  Book Now
                </button>
              </form>
            </Modal.Body>
          </Modal> */}
          </div>
        </div>
        <div className="sticky-top bg-white hidden-spacer"></div>
      </div>
    </>
  );
};

// const Landingpage2 = () => {
//   return (
//     <>
//       <div className="mt-5 pt-3">
//         <p className="p2t1 headingstextstyle ">
//           Welcome to Pragnya Mobility, where our journey begins with a passion
//           for innovation and a deep commitment to environmental sustainability.
//         </p>
//         <p className="p2t2 normaltextstyle">
//           Our mission is to bring yachting into a new era with a product
//           offering of progressive, electrified solar catamarans and sailing
//           yachts that are designed and engineered without compromise. Combining
//           self-sufficient sailing and comprehensive customer experience, PRAGNYA
//           Yachts offers the best boats for forward-thinking explorers of the
//           oceans.
//         </p>
//         <button className="p2btn bgcolorforbtnhover">Learn more</button>
//       </div>
//     </>
//   );
// };

const customStyle = {
  marginTop: "540px",
};
const Landingpage3 = () => {
  return (
    <>
      <div className="">
        <div className="">
          <div
            className="headerp4"
            style={{ backgroundImage: `url(${Frame4})`, height: "100vh" }}
          >
            <p className="p4_text1 headingstextstyle">ELYSIAN &nbsp; ODYSSEY</p>
            <div className="container p4cols normaltextstyle">
              <div className="row pc-2" style={customStyle}>
                <div className="col text-white">
                  Salling Range <br /> 500 mile+
                </div>
                <div className="col-1">
                  <img height={50} src={VerticalLine} alt="" />
                </div>
                <div className="col text-white">
                  0 to 80% <br /> 30 Mins
                </div>
                <div className="col-1">
                  <img height={50} src={VerticalLine} alt="" />
                </div>
                <div className="col text-white">
                  Battery Life <br />
                  250,000 km
                </div>
                <div className="col"></div>
                <div className="col mt-2">
                  <button className="p3btn1 bgcolorforbtnhover">Explore</button>
                </div>
                <div className="col mt-2">
                  <button className="p1btn">Enquiry</button>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-top bg-white hidden-spacer"></div>
        </div>
      </div>
    </>
  );
};
// const Landingpage4 = () => {
//   return (
//     <>
//       <div>
//         <div className="headerp4" style={{ backgroundImage: `url(${Frame5})` }}>
//           <p className="p4_text1 headingstextstyle">
//             T i d a l &nbsp; E l e g a n c e
//           </p>
//           <div className="container p4cols">
//             <div className="row" style={customStyle}>
//               <div className="col text-white" >
//                 Salling Range <br /> 500 mile+
//               </div>
//               <div className="col-1">
//                 <img height={50} src={VerticalLine} alt="" />
//               </div>
//               <div className="col text-white" >
//                 0 to 80% <br /> 30 Mins
//               </div>
//               <div className="col-1">
//                 <img height={50} src={VerticalLine} alt="" />
//               </div>
//               <div className="col text-white">
//                 Battery Life <br />
//                 250,000 km
//               </div>
//               <div className="col"></div>
//               <div className="col mt-2">
//                 <button className="p3btn1 bgcolorforbtnhover">Explore</button>
//               </div>
//               <div className="col mt-2">
//                 <button className="p1btn">Enquiry</button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="sticky-top bg-white hidden-spacer"> </div>
//       </div>
//     </>
//   );
// };
const Landingpage5 = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const texts = [
    <div key={0} className="text-start maindivpp1">
      <div className="forppwidth-landing">
        <p className="pp6_t1 headingstextstyle">EASY</p>
      </div>
      <hr className="hrline54" />
    </div>,
    <div key={0} className="text-start maindivpp2">
      <div className="forppwidth-landing">
        <p className="pp6_t1 headingstextstyle">CLEAN</p>
      </div>
      <hr className="hrline54" />
    </div>,
    <div key={0} className="text-start maindivpp3">
      <div className="forppwidth-landing">
        <p className="pp6_t1 headingstextstyle">SERENE</p>
      </div>
      <hr className="hrline54" />
    </div>,
  ];
  const imagesSets = [
    [
      { src: SunriseDesktop, className: "first-image87" },
      {
        text: "Fewer moving parts means less effort maintaining and more time on the water.",
        className: "right-text1 normaltextstyle",
      },
    ],
    [
      { src: MistyWake, className: "first-image87" },
      {
        text: "All the thrill of a high performance sport boat, with none of the emissions or pollution.",
        className: "right-text2 normaltextstyle",
      },
    ],
    [
      { src: WeldingFactory, className: "first-image87" },
      {
        text: "Say goodbye to noisy combustion engines; a whisper-quiet electric motor won t drown out your conversations.",
        className: "right-text3 normaltextstyle",
      },
    ],
  ];

  const handleTextClick = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [texts.length]);

  // for transitions
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <>
      {/* <div className="container">
        <div className="row">
          <div className="col">
            <div className="card rounded-0 border-0 border-bottom border-dark landing6_heightwidthes">
              <p className="p6t1 headingstextstyle">Easy</p>
              <p className="p6t2 normaltextstyle">
                Fewer moving parts means less effort maintaining and more time
                on the water.
              </p>
            </div>
          </div>
          <div className="col">
            <div className="card rounded-0 border-0 border-bottom border-dark landing6_heightwidthes">
              <p className="p6t3 headingstextstyle">Clean</p>
              <p className="p6t4 normaltextstyle">
                All the thrill of a high performance sport boat, with none of
                the emissions or pollution.
              </p>
            </div>
          </div>
          <div className="col">
            <div className="card rounded-0 border-0 border-bottom border-dark landing6_heightwidthes">
              <p className="p6t5 headingstextstyle">Serene</p>
              <p className="p6t6 normaltextstyle">
                Say goodbye to noisy combustion engines; a whisper-quiet
                electric motor won t drown out your conversations.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div style={{marginTop:"30px"}}>
                <div style={{ display: 'flex' }}>
                    {texts.map((text, index) => (
                        <div
                            key={index}
                            style={{
                                flex: 1,
                                opacity: index === activeIndex ? 1 : 0.5,
                                cursor: 'pointer',
                                transition: 'opacity 0.5s ease',
                            }}
                            onClick={() => handleTextClick(index)}
                        >
                            {text}
                        </div>
                    ))}
                </div>
                <div>
                    {imagesSets[activeIndex].map((image, idx) => (
                        <img
                            key={idx}
                            src={image.src}
                            alt=""
                            className={image.className}
                        />
                    ))}
                </div>
            </div> */}
      <div style={{ marginTop: "60px", height: "100vh" }}>
        <div style={{ display: "flex" }}>
          {texts.map((text, index) => (
            <div
              key={index}
              style={{
                flex: 1,
                opacity: index === activeIndex ? 1 : 0.5,
                cursor: "pointer",
                transition: "opacity 0.5s ease",
              }}
              onClick={() => handleTextClick(index)}
            >
              {text}
            </div>
          ))}
        </div>
        <div>
          {imagesSets[activeIndex].map((item, idx) =>
            item.src ? (
              <img key={idx} src={item.src} alt="" className={item.className} />
            ) : (
              <div key={idx} className={item.className + 'pc3'}>
                {item.text}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};
// const Landingpage6 = () => {
//   return (
//     <>
//       <div className="p7text">
//         <p className="p7t1 headingstextstyle">Our Technology</p>
//         <p className="p7t2">Enabling a unique experience</p>
//       </div>
//       <button className="p7btn bgcolorforbtnhover">Learn more</button>
// <div className="container">
// <div className="row">
//   <div className="col">
//     <img width={295} src={vedio1} alt="" />
//   </div>
//   <div className="col">
//     <img width={295} src={vedio2} alt="" />
//   </div>
//   <div className="col">
//     <img width={295} src={vedio3} alt="" />
//   </div>
// </div>
// </div>
//     </>
//   );
// };

const Landingpage7 = () => {
  return (
    <>
      <div className="container Landingpage7_main-div">
        <div className="empdiv"></div>
        <p className="p3t1 headingstextstyle">ABOUT PAGNYA MOBILITY</p>
        <div className="landing_p2contnet" style={{ marginLeft: "-110px" }}>
          <img className="p3_img1" src={imagep31} alt="" />
          <img className="p3_img2" src={logowithbg} alt="" />
          <div
            className="card p3card rounded-0"
            style={{ backgroundColor: "black" }}
          >
            <div className="card-body">
              <h5 className="card-title text-white p3_text1 headingstextstyle">
                PRAGNYA MOBILITY
              </h5>
              <p className="card-text text-white p3_text2 normaltextstyle">
                At Futuristic Electric Boats and Yachts, we are passionate about
                boats and yachts. Our journey began in 2005 when we bought our
                first boat. Since then, we have been exploring the open waters
                and learning everything there is to know about boats. Our
                passion for boating turned into a business, and we are proud to
                offer the best boating experience to our customers.
              </p>
              <button className="p3btn1 bgcolorforbtnhover">Learn more</button>
              <button className="p1btn">Book now</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const Landingpage8 = () => {
//   return (
//     <>
//       <div className="Landingpage8_main-div">
//         <p className="p8t1 headingstextstyle">
//           Stay up to date on all the latest <br /> PRAGNYA MOBILITY news
//         </p>
//         {/* <p className="p8t2 normaltextstyle">
//           Our newsletters are a concentrated blast of all the latest from
//           PRAGNYA MOBILITY: news, events, product information, and more.
//         </p> */}
//         <p className="p8t2">
//           {/* <input className="input" name="text" placeholder="&nbsp;First Name" type="First Name" />
//         <input className="input" name="text" placeholder="&nbsp;last Name" type="last Name" /> */}
//           <input
//             className="input email__input3"
//             name="text"
//             placeholder="&nbsp;Email Address"
//             type="Email Address"
//           />
//           <button className="p8btn">S u b s c r i b e</button>
//         </p>
//       </div>
//     </>
//   );
// };
export default Landingpage;
