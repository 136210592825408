import React, { useEffect } from "react";
// scss
import "../styles/Aboutus.scss";
// images
import eximage from "../images/Frame5.png";
// jsx
import Footer from "./Footer.jsx";
import Navbar from "./Navbar.jsx";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="hiding_scrol about_mainPage">
      <div style={{ scrollSnapAlign: "start" }}>
        <Navbar />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Aboutus1 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Aboutus2 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Aboutus3 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Aboutus4 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Aboutus5 />
      </div>
      <div style={{ scrollSnapAlign: "start" }}>
        <Footer />
      </div>
    </div>
  );
};

const Aboutus1 = () => {
  return (
    <>
      <div className="dsfjasdf">
        <div className="col-40">
          <p className="my_abouttext text-center">ABOUT PRAGNYA</p>
        </div>
        <div className="col-60">
          <div className="bg-image575">
            <p className="centered-text">Our mission is to electrify the ocean.</p>
          </div>
        </div>
      </div>
    </>
  );
};

const Aboutus2 = () => {
  return (
    <>
      <div className="header aboutusimage123 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${eximage})` }} >
        <div className="text-center aboutusimagetext123 text-white normaltextstyle">
          <p>
            Pragnya is developing the next generation of watercraft, starting
            with electric sport boats.
          </p>
        </div>
      </div>
    </>
  );
};

const Aboutus3 = () => {
  return (
    <>
      <div className="container about_3">
        <div className="row mt-5">
          <div className="col-5 d-none d-lg-block d-md-block">
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 forwidth40 mt-4">
            <h2 className="headingstextstyle a3_head">The Technology</h2>
            <p className="mt-2 normaltextstyle">
              We build electric boats from scratch by blending modern aerospace
              design and manufacturing techniques with traditional marine
              craftsmanship. By designing our battery packs, powertrain, thermal
              control systems and software in-house, we create boats with a
              cohesive feel, optimal performance, and new features you won’t
              find on any other watercraft.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Aboutus4 = () => {
  return (
    <>
      <div className="container a_5">
        <div className="row">
          <div className="col-5 col-5 d-none d-lg-block d-md-block"></div>
          <div className="col-lg-7 col-md-7 col-sm-12 forwidth40">
            <h2 className="headingstextstyle a5_head">The Team</h2>
            <p className="normaltextstyle">
              Led by a team of former rocket engineers, we’re developing boats
              at lightning speed. We brought our first model, the Arc One, to
              market in just over a year since our founding in 2021. Thanks to
              strong support from world-class investors, we’re continuing to
              grow our world-class team in LA and beyond.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Aboutus5 = () => {
  return (
    <>
      <div className="container a_6">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 mt-5 mb-5 forwidth40">
            <h2 className="headingstextstyle a6_head">The Vision</h2>
            <p className="normaltextstyle">
              The Arc One set a new industry standard as the most powerful
              electric boat commercially available at launch. We’re continuing
              to chart new waters with future models currently in development to
              make boating accessible, more enjoyable, and more sustainable.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;